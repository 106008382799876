import { Container } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';
import toast_config from "../../toast_config";
import ScrollToTop from "./ScrollToTop";
import Footer from '../common/footer';
import Header from '../common/header';
import Ticker from '../common/ticker';

import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/admin.css';

import styles from "../../assets/css/chatbot.module.css";
import chatbotIcon from "../../assets/img/chatbot_icon.png";

import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

const CommonLayout = ({ children, fixed }) => {

    const [openChat, setOpenChat] = useState(false);
    const [showChatIcon, setShowChatIcon] = useState(false);
    const [showChatMessage, setShowChatMessage] = useState(true);

    const handleOpenChat = () => {
        setOpenChat(!openChat);
    }

    const viewportHeight = window.innerHeight;

    const scrollFunction = () => {
        let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll >= viewportHeight) {
            setShowChatIcon(true);
        }
    }

    useEffect(() => {
        document.addEventListener('wheel', function () { scrollFunction() });
    }, [])

    return (
        <>
            <Grow in={showChatIcon && !openChat}>
                <div className={styles.chatbotBtnContainer}>
                    {
                        showChatMessage &&
                        <div className={styles.iconMsgContainer}>
                            <CloseIcon className={styles.chatCloseIcon} onClick={() => setShowChatMessage(false)} />
                            <div role="button" onClick={handleOpenChat} className={styles.msgContainer}>
                                <span>
                                    Hello! I am Emily. Welcome to Sciative.
                                </span>
                            </div>
                        </div>
                    }
                    <button onClick={handleOpenChat} className={styles.chatBtn}>
                        <img src={chatbotIcon} alt="chatbot icon" />
                    </button>
                </div>
            </Grow>

            <Slide direction="up" in={openChat} mountOnEnter unmountOnExit>
                <div className="d-flex justify-content-center">
                    <div className={styles.iFrameContainer}>
                        <CloseIcon className={styles.chatCloseIcon} onClick={() => setOpenChat(false)} />
                         <iframe src="https://apti.sciative.com/chat/chatbot/chatbox/" /> 
                       
                    </div>
                </div>
            </Slide>

            <ScrollToTop />
            <Ticker props={{ fixed: fixed, linkText: "Listen now!", targets: "blank", tPosition: "sticky_ticker" }} />
            <Header fixed={fixed} />
            {children}
            <Footer />
            <ToastContainer 
            position={toast_config.position}
            autoClose={toast_config.autoClose}
            hideProgressBar={toast_config.hideProgressBar}
            closeOnClick={toast_config.closeOnClick}
            pauseOnHover={toast_config.pauseOnHover}
            draggable={toast_config.draggable}
            theme={toast_config.theme} 
          
            />
        </>
    )
}

export default CommonLayout;