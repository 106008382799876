import { useState, useEffect, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { HelmetProvider, Helmet } from "react-helmet-async"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import { fetchToken } from "../../Auth"
import { toast } from "react-toastify"
import axios from "axios"
import RecommendedBlogs from "./RecommendedBlogs"
import Contents from "./Contents"
import Form from "../../components/Forms/Form"
import Share from "./components/Share_2.js"
import "../../assets/css/blogs.css"
import { GlobalUrl } from "../../global"
import Contributor from "../../components/common/contributor"
import { Link } from 'react-router-dom'
const BlogPage = () => {
  const [pageId, setPageId] = useState("")
  const [url, setUrl] = useState("")
  const [blog, setBlog] = useState(null)
  const [template, setTemplate] = useState(null)
  const [headings, setHeadings] = useState([])
  const [currentHeading, setCurrentHeading] = useState("Introduction")
  const [formModal, setFormModal] = useState(false)
  const [mainImage, setMainImage] = useState('')
  const wasAboveThreshold = useRef(false) 
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    if (width > 600 && !wasAboveThreshold.current) {
      populateBlog()
    }
    // Update the ref to track current state
    wasAboveThreshold.current = width > 600

    return () => window.removeEventListener("resize", handleResize)

  }, [width])
  const Fprops = {
    template: 2,
    text_color: "text-black",
    form_name: "blogs-page",
    fields: [{ name: "email", required: true }],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss()
      if (res.status == "success") {
        toast.success("Form Submitted successfully")
      } else {
        toast.error(res.data)
      }
    },
  }

  const [props, setProps] = useState({
    template: 1,
    form_name: "blog-page", //
    fields: [
      { name: "name", required: true },
      { name: "email", required: true },
    ],
    title: "",
    content_upper: "upper content",
    content_lower: "lower content",
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss()
      if (res.status == "success") {
        toast.success("Form submitted successfully!")
      } else {
        toast.error(res.data)
      }
      setFormModal(false)
    },
  })

  const subscribeProps = {
    template: 2,
    text_color: "text-black",
    form_name: "blogs-page", //
    fields: [
      {
        name: "email",
        required: true,
      },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss()
      if (res.status == "success") {
        toast.success("Form submitted successfully!")
      } else {
        toast.error(res.data)
      }
    },
  }

  const guestSpeakerProps = {
    template: 1,
    form_name: "become-a-guest-speaker",
    fields: [
      { name: "name", required: true },
      { name: "email", required: true },
      { name: "company", required: true },
      { name: "topic", required: true },
    ],
    title: "Become Our Guest Speaker",
    content_upper:
      "Got an amazing story to share? Become a featured guest on our podcast and share your insights with our audience. Submit your topic idea and let's make magic together on air.",
    content_lower: "Your voice could reach thousands of eager listeners!",
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss()
      if (res && res.status && res.status === "success") {
        setGuestModal(false)
        toast.success("Form submitted successfully!")
      } else {
        toast.error(res.data)
      }
    },
  }
  const guestWriterProps = {
    template: 1,
    form_name: "blogs-become-a-guest-writer",
    fields: [
      { name: "name", required: true },
      { name: "email", required: true },
      { name: "company", required: true },
      { name: "topic", required: true },
    ],
    title: "Become a Guest Writer",
    content_upper:
      "Got a great idea? We want to hear it! Submit your blog topic here and become a valued contributor to our site.",
    content_lower: "Your insights could reach thousands!",
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss()
      setGuestModal(false)
      if (res.status == "success") {
        toast.success("Form submitted successfully!")
      } else {
        toast.error(res.data)
      }
    },
  }

  const [guestModal, setGuestModal] = useState(false)
  const [writerModal, setWriterModal] = useState(false)

  const { blogId } = useParams()
  const navigate = useNavigate()

  const getBlog = async (url) => {
    try {
      const response = await axios.get(`/get_blog/${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("nuprice_access_token")}`,
        },
      })
      if (response.status == 200 && response.data !== undefined) {
        setPageId(response.data.pageId)
        setBlog(response.data)
        getTemplate(response.data.templateId)
      }
    } catch (err) {
      console.error("Error", err)
      if (err.response.status === 400) {
        navigate("/error")
      }
      if (err.response.status === 401) {
        navigate("/login")
      }
    }
  }

  const getTemplate = async (templateId) => {
    try {
      const response = await axios.get(`/get_template/${templateId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken("nuprice_access_token")}`,
        },
      })
      if (response.status == 200 && response.data !== undefined) {
        setTemplate(response.data)
      }
    } catch (err) {
      console.error("Error", err)
      if (err.response.status === 401) {
        navigate("/login")
      }
    }
  }

  useEffect(() => {
    if (blogId && !blog) {
      setUrl(blogId)
      getBlog(blogId)
    }
  }, [blogId, blog]) // Added blog to dependencies

  useEffect(() => {
    if (template && blog) {
      populateBlog()
    }
  }, [template, blog])

  const options = {
    root: document.querySelector("#scrollArea"),
    rootMargin: "-50% 0% -50% 0%",
    threshold: 0,
  }
 
  // function scrollFunction() {
  //   // Get scroll position
  //   var winScroll = window.scrollY || document.documentElement.scrollTop

  //   // Check if we're at the top and update heading
  //   // if (winScroll <= 5) {
  //   //   setCurrentHeading("Introduction")
  //   // }

  //   // Update progress bar
  //   var additionalContent = document.getElementById("additional-content")
  //   if (additionalContent) {
  //     var height = additionalContent.getBoundingClientRect().height
  //     var scrolled = (winScroll / height) * 100
  //     if (scrolled > 100) scrolled = 100
  //     document.getElementById("myBar").style.width = scrolled + "%"
  //   }
  // }
  const intersectionCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        Array.from(document.querySelector(".toc-nav").querySelectorAll("li")).forEach((h) => h.classList.remove("bold"))
        document.getElementById(`heading-${entry.target.id}`).classList.add("bold")
        setCurrentHeading(entry.target.innerText)
      }
    })
  }
  const observer = new IntersectionObserver(intersectionCallback, options)

  const showForm = (formName) => {
    const temp = props
    temp["form_name"] = formName
    setProps(temp)
    setFormModal(true)
  }

  const populateBlog = () => {

    const blogSection = document.getElementById("blog-section")
    const additionalContent = document.getElementById("additional-content")

    if (!additionalContent || !template || !blog) return

    // Populate blog section only if it exists (desktop view)
    if (blogSection) {
      blogSection.innerHTML = ""
      JSONtoHTML(template.structure, blogSection)
    }
 // Populate blog section
 let content = blog.additionalContent
    const arr = content.split("##")
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i].includes("__")) {
        arr[i] = `<a target='_blank' href='//${arr[i].split("__")[1]}'>${arr[i].split("__")[0]}</a>`
      }
    }
    content = arr.join("")
    document.getElementById("additional-content").innerHTML = content

    //Extract form buttons and attach onclick function to them to show forms
    const buttons = Array.from(document.getElementById("additional-content").querySelectorAll(".clickable-form-button"))
    buttons.forEach((btn) => {
      btn.addEventListener("click", () => showForm(btn.getAttribute("data-attached-form")))
    })

    //Set Headings
    let elements = Array.from(document.querySelector("#additional-content").querySelectorAll("h2, h3, h4, h5"))
    for (let i = 0; i < elements.length; i += 1) {
      if (elements[i].innerText.trim() !== "") {
        observer.observe(elements[i])
      }
      elements[i].setAttribute("id", i)
    }
    elements = elements
      .filter((elem) => elem.innerText.trim() !== "")
      .map((elem) => ({
        id: elem.getAttribute("id"),
        text: elem.innerText,
        level: Number(elem.nodeName.charAt(1)),
      }))
    setHeadings(elements)

    for (const key in blog["images"]) {
      try {
        const name = key
        let type
        try {
          if (blog["images"][key]["type"].includes("image/")) {
            type = blog["images"][key]["type"].split("/")[1]
          } else {
            type = blog["images"][key]["type"]
          }
        } catch (err) {
          type = "jpeg"
        }
        if (!type) {
          type = "jpeg"
        }
        let layout = ""
        let altText = ""
        if (name !== "img-main-img") {
          layout = blog["images"][key]["layout"]
          altText = blog["images"][key]["altText"]
        }
        populateImages(name, type, layout, altText)
      } catch (err) {
        console.log(err)
      }
    }
  }

  async function populateImages(name, type, layout, altText) {
    try {
      const response = await fetch(`/get_blog_image/${"blog_" + String(pageId) + "_" + name}.${type}`)
      const data = await response.json()
      if (name === "img-main-img") {
        //Render featured image
        setMainImage(data)
        const styleTag = document.createElement("style")
        const cssRules = `
                    .blog-title-section::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url('${data}');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        mix-blend-mode: multiply;
                        /* to blend the overlay with the image */
                    }
                `
        styleTag.innerHTML = cssRules
        document.head.appendChild(styleTag)

        const meta = document.createElement("meta")
        meta.setAttribute("property", "og:image")
        meta.setAttribute("content", data)
        document.head.appendChild(meta)
      } else {
        try {
          document.getElementById(name).setAttribute("alt", altText)
          document.getElementById(name).setAttribute("src", data)
          document.getElementById(name).classList.add(`blog-img-${layout}`)
          document.getElementById(name).classList.add("my-3")
        } catch (err) {
          console.log("img err")
        }
      }

      // .then(blob => {
      //     // Convert the blob to a URL
      //     const url = URL.createObjectURL(blob);

      //     if (name === 'img-main-img') {

      //     } else {
      //         document.getElementById(name).setAttribute('src', url)
      //         document.getElementById(name).classList.add(`blog-img-${layout}`)
      //         document.getElementById(name).classList.add('my-3')
      //     }

      // })
    } catch (err) {
      console.error("Error", err)
      if (err.response.status === 401) {
        navigate("/login")
      }
    }
  }

  function JSONtoHTML(json, parentElement) {
    const element = document.createElement(json.tagName)
    if ("className" in json) {
      const classes = json.className.split(" ")
      classes.forEach((cl) => element.classList.add(cl))
    }

    if (json.children) {
      json.children.forEach((childJson) => JSONtoHTML(childJson, element))
    } else {
      if (json.userInput) {
        if (json.key in blog["inputs"]) {
          if (json.key === "blogMainImg") {
            // element.setAttribute('src', blog['inputs'][json.key])
            element.setAttribute("src", "")
            element.setAttribute("id", "main-img")
          } else if (json.key === "blogTitle") {
            element.textContent = blog["inputs"][json.key]
            element.classList.add("blog-title-main")
          } else {
            element.textContent = blog["inputs"][json.key]
          }
        } else {
          element.textContent = ""
        }
      } else if (json.takeFromState) {
        if (json.key === "categories") {
          const cats = blog["categories"]
            .sort()
            .map(
              (cat) =>
                `<div class='mx-1 category-tile py-1 px-2'><a href='/research-articles/category/${cat}'>${cat}</a></div>`,
            )
          element.innerHTML = cats.join("")
        } else if (json.key === "authorName") {
          element.textContent = blog["author"]["authorName"]
        }
        // else if (json.key === 'authorImg') {
        //     element.setAttribute('src', blog['author']['profileImg'])
        // }
      } else {
        element.textContent = json.textContent
      }
    }
    parentElement.appendChild(element)
  }
  const targetRef = useRef(null)

  useEffect(() => {
    if (targetRef.current) {
      const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }

      const handleIntersection = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // You can perform any action here when the element reaches the top
          } else {
          }
        })
      }

      const barObserver = new IntersectionObserver(handleIntersection, observerOptions)
      if (targetRef.current) {
        barObserver.observe(targetRef.current)
      }

      return () => {
        barObserver.disconnect()
      }
    }
  }, [targetRef])
  function formatDate(dateString) {
    const date = new Date(dateString)
  
    // Get day with ordinal suffix (1st, 2nd, 3rd, 4th, etc.)
    const day = date.getDate()
    const ordinal = (day) => {
      if (day > 3 && day < 21) return "th"
      switch (day % 10) {
        case 1:
          return "st"
        case 2:
          return "nd"
        case 3:
          return "rd"
        default:
          return "th"
      }
    }
  
    // Format the date
    return `${day}${ordinal(day)} ${date.toLocaleString("default", {
      month: "long",
      year: "numeric",
    })}`
  }
  function scrollFunction() {
    // Get scroll position
    const scrollPosition = Math.max(
      window.scrollY,
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    
  
    // Check if we're at or very close to the top
    // if (scrollPosition <= 25) {
    //   setCurrentHeading("Introduction")
    // }

    // Update progress bar
    var additionalContent = document.getElementById("additional-content")
    if (additionalContent) {
      var height = additionalContent.getBoundingClientRect().height
      var scrolled = (scrollPosition / height) * 100
      if (scrolled > 100) scrolled = 100
      document.getElementById("myBar").style.width = scrolled + "%"
    }
  }


  useEffect(() => {
    document.body.scrollTop = 0

    // Use both wheel and scroll events to ensure we catch all scroll actions
    document.addEventListener("wheel", scrollFunction)
    document.addEventListener("scroll", scrollFunction)
    // document.addEventListener("touchmove", scrollFunction)
    document.addEventListener("touchmove", () => {
      if (window.scrollY <= 10) {
    var winScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
     if (winScroll <= 0) {
      //  setCurrentHeading("")
     }
      }
    })
    // document.addEventListener("touchend", () => {
    //   if (window.scrollY === 0) {
    // var winScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //  if (winScroll <= 5) {
    //    setCurrentHeading("")
    //  }
    //   }
    // })
    document.getElementById("root").style.height = "100vh"

    return () => {
      document.removeEventListener("wheel", scrollFunction)
      document.removeEventListener("scroll", scrollFunction)
      document.removeEventListener("touchmove", scrollFunction)
      // document.removeEventListener("touchend", scrollFunction)
    }
  }, [])

  return (
    <>
      {blog ? (
        <HelmetProvider>
          <Helmet>
            <title>{blog["title"]}</title>
            <link rel="canonical" href={GlobalUrl + "/research-articles/" + blog["url"]} />
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="csrf_token" content="" />
            <meta property="type" content="website" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="_token" content="" />
            <meta name="robots" content="noodp" />
            {blog["desc"] ? <meta name="description" content={blog["desc"]} /> : null}
            {blog["keywords"] ? <meta name="keywords" content={blog["keywords"].join(", ")} /> : null}
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={blog["title"]} />
            <meta property="og:quote" content="quote" />
            <meta property="og:hashtag" content="#ai" />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={"www.sciative.com/blogs/" + blog["url"]} />
            <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
            <meta property="og:description" content={blog["desc"]} />
            <script type="application/ld+json">{JSON.stringify(blog["schemaMarkup"])}</script>
          </Helmet>
          {width <= 600 ? (
            <section>
            <div className="blog-container">
              <article className="blog-content">
                <div className="title-container">
                  <h1 className="blog-title">{blog.title}</h1>
                </div>
      
                <div className="blog-meta">
                <div className="d-flex flex-column mb-3">
              <div className="author-date-container">
                <span className="author-name">{blog.author?.authorName}</span>
                <span className="text-grey separator">|</span>
                <span className=" date">{formatDate(blog.created)}</span>
              </div>
              {blog.blogReadTime &&
              <span className="text-grey read-time">{blog.blogReadTime} min read</span>
}
            </div>
                  <div className="meta-details">
                    <div className="categories-wrapper">
                      {blog.categories.sort().map((category, index) => (
                        <Link key={index} to={`/research-articles/category/${category}`} className="category-link">
                          <span className="category-tile">{category}</span>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </article>
      
              <div className="blog-image-container">
                <img src={mainImage || "/placeholder.svg"} className="blog-image" alt={blog.title} />
              </div>
            </div>
          </section>
          ) : (
            <>
            <section id="blog-section" className="bg-white  w-100 justify-content-center"></section>
            </>
          )}
          <div className="bg-white row">
            <div
              ref={targetRef}
              className="w-100 sticky-section progress-section d-flex flex-column justify-content-end"
            >
              <p className="mx-5 mb-0 bigHead bold">{currentHeading}</p>
              <div class="progress-container">
                <div class="my-progress-bar" id="myBar"></div>
              </div>
              {/* <ProgressBar
                                    color1="#eee"
                                    color2="#00acee"
                                    height="4px"
                                    position="relative"
                                /> */}
              {/* <ProgressBar /> */}
            </div>
            <div className="col-sm-12 col-md-4 d-none d-md-block">
              {blog ? <Contents url={blog["url"]} headings={headings} /> : null}
            </div>
            <div className="col-sm-12 col-md-8">
              <div id="additional-content" className="container p-3 p-sm-2"></div>
              <div className="d-block d-md-none">
                <div className="container my-4 d-flex justify-content-center align-items-center gap-4">
                  <Share url={url} />
                </div>
                <div className="container my-4">
                  <Form {...subscribeProps} />
                </div>
              </div>
            </div>
          </div>

          {/* <section className='w-100 bg-beige'>
                            <div className='container'>
                                <div className='w-100 row p-5'>
                                    <div className='col-sm-12 col-md-6 p-4'>
                                        <h4 className='bold'>Subscribe to our Newsletter</h4>
                                        <p className='thin mb-0'>Stay tuned with the latest industry updates.</p>
                                    </div>
                                    <div className='col-sm-12 col-md-6 p-2'>
                                        <div className='w-100 row'>
                                            <Form {...subscribeProps} />
                                            <small className='small-2 mt-2'>By clicking subscribe, you confirm that you understand and agree to the <a href='/privacy-policy'>Privacy Policy</a></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}

          {blog ? (
            <>
              <hr className="m-0" />
              <section className="bg-white p-3 row">
                <div className="col-12">
                  <RecommendedBlogs pageId={blog["pageId"]} categories={blog["categories"]} />
                </div>
              </section>
            </>
          ) : null}

          <Contributor type="light" />
        </HelmetProvider>
      ) : null}

      <Dialog
        open={formModal}
        onClose={() => setFormModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <section>
            <Form {...props} />
          </section>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default BlogPage

