import {useEffect, useState} from 'react';
import { LinkedInEmbed } from 'react-social-media-embed';
import IconButton from '@mui/material/IconButton';
import SciativeInMedia from '../../../components/common/SciativeInMedia';
import instagram from '../../../assets/img/instagram.png'
import facebook from '../../../assets/img/facebook.png'
import youtube from '../../../assets/img/youtube.png'
import linkedin from '../../../assets/img/linkedin.png'
import x from '../../../assets/img/x.png'

import handles from '../../../data/socialMediaHandles';
import { fetchToken } from '../../../Auth';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';


const SocialMedia = () => {

    const navigate = useNavigate()
    const [linkedInUrl,setLinkedInUrl] = useState('')
    const getData = async () => {

        try {
            const response = await axios.get(`ra_talks/get_latest_shorts`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setLinkedInUrl(response.data.linkedInUrl)
            }
        } catch (err) {

            if (err.response.status === 400) {
                navigate('/error')
            }
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        getData()
    }, [])
    

    return(
        <div className='bg-beige container w-100 d-flex flex-column flex-md-row'>
            <div className='col-sm-12 col-md-6 p-2 d-flex justify-content-center'>
                <iframe className='w-100 linked-in-iframe' src={linkedInUrl || "https://www.linkedin.com/embed/feed/update/urn:li:share:7241709775917400066"}
                 frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
            </div>
            <div className='col-sm-12 col-md-6 p-2 px-4 d-flex flex-column align-items-center'>
                <div className='w-100'>
                    <SciativeInMedia />
                </div>
                    
                <div className='mt-4'><h4 className='bold text-center'>Follow us on our social media channels for such insights</h4></div>
                <div className='mt-2 d-flex justify-content-evenly w-100 align-items-center'>
                    <a href={`//${handles['instagram']}`} target="_blank" className='mx-1 social-media-button'>
                        <img className='sm-icon' src={instagram}/>
                    </a>
                    <a href={`//${handles['facebook']}`} target="_blank" className='mx-1 social-media-button'>
                        <img className='sm-icon' src={facebook}/>
                    </a>
                    <a href={`//${handles['youtube']}`} target="_blank" className='mx-1 social-media-button'>
                        <img className='sm-icon' src={youtube}/>
                    </a>
                    <a href={`//${handles['x']}`} target="_blank" className='mx-1 social-media-button'>
                        <img className='sm-icon' src={x}/>
                    </a>
                    <a href={`//${handles['linkedin']}`} target="_blank" className='mx-1 social-media-button'>
                        <img className='sm-icon' src={linkedin}/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default SocialMedia;