import React, {useState} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Form from '../../components/Forms/Form';

import chats from '../../assets/img/chats.png'
import chats2 from '../../assets/img/chats2Resize.png'

import notes from '../../assets/img/notes.png'
import notes2 from '../../assets/img/notes2.png'

import { toast } from 'react-toastify';

const Contributor = ({ type }) => {

    const [guestModal, setGuestModal] = useState(false);
    const [writerModal, setWriterModal] = useState(false);

    const guestSpeakerProps = {
        template: 1,
        form_name: "become-a-guest-speaker",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become Our Guest Speaker",
        content_upper: "Got an amazing story to share? Become a featured guest on our podcast and share your insights with our audience. Submit your topic idea and let's make magic together on air.",
        content_lower: "Your voice could reach thousands of eager listeners!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                setGuestModal(false);
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        }
    }

    const guestWriterProps = {
        template: 1,
        form_name: "blogs-become-a-guest-writer",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become a Guest Writer",
        content_upper: "Got a great idea? We want to hear it! Submit your blog topic here and become a valued contributor to our site.",
        content_lower: "Your insights could reach thousands!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            setGuestModal(false);
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form submitted successfully!');
              } else {
                toast.error(res.data)
              }
        }
    }

    return (
        <>
            {
                type === 'dark' ?
                    <section className='w-100 bg-black'>
                        <div className='container'>
                            <div className='w-100 row m-0 bg-black text-white p-sm-1 p-md-3 p-lg-5'>
                                <div className='col-sm-12 col-md-6 p-2 mb-3'>
                                    <div className='w-100 row'>
                                        <div className='col-2 p-sm-3 d-flex justify-content-center'><img alt='chats' className='h-30' src={chats2} /></div>

                                        <div className=' col-10  p-sm-3'>
                                            <h6 className='bold'>Tune In to Our Road Ahead Talks</h6>
                                            <p className='mt-2 thin small'>Dive into thought-provoking discussions that inspire action and change. Explore the forefront of technology and ideas through engaging conversations.</p>
                                            <button onClick={() => setGuestModal(true)} className='contri-btn-1 small p-2' >Become a guest speaker</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 p-2'>
                                    <div className='w-100 row'>
                                        <div className='col-2 p-sm-3 d-flex justify-content-center'><img className='h-30' src={notes2} /></div>
                                        <div className='col-10 p-sm-3'>
                                            <h6 className='bold'>Write a Research Article for Our Knowledge Warehouse</h6>
                                            <p className='mt-2 thin small'>Exploring innovative ideas and cutting-edge research to drive progress and innovation.</p>
                                            <button onClick={() => setWriterModal(true)} className='contri-btn-1 small p-2'>Become a Guest Contributor</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className='bg-white '>
                            <div className="container">
                                <div className='w-100 row m-0 bg-white p-sm-1 p-md-3 p-lg-5'>
                                    <div className='col-sm-12 col-md-6 p-2 mb-3'>
                                        <div className='w-100 row'>
                                            <div className='col-2 p-sm-3 d-flex justify-content-center'><img alt='chats' className='h-30' src={chats} /></div>
                                            <div className='col-10 p-sm-3'>
                                                <h6 className='bold'>Tune in to our Road Ahead Talks</h6>
                                                <p className='mt-2 thin small'>Dive into thought provoking-discussions that inspire action and change. Explore the forefront of technology and ideas through engaging conversations.</p>
                                                <button onClick={() => setGuestModal(true)} className='contri-btn-2 small p-2 bg-transparent' >Become a guest speaker</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-6 p-2 mb-3'>
                                        <div className='w-100 row'>
                                            <div className='col-2 p-sm-3 d-flex justify-content-center'><img alt='notes' className='h-30' src={notes} /></div>
                                            <div className='col-10 p-sm-3'>
                                                <h6 className='bold'>Write a research article for our Knowledge Warehouse</h6>
                                                <p className='mt-2 thin small'>Exploring innovative ideas and cutting-edge research to drive progress and innovation.</p>
                                                <button onClick={() => setWriterModal(true)} className='contri-btn-2 small p-2 bg-transparent'>Become a guest contributor</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </section>
            }

<Modal
                    show={guestModal}
                    onHide={() => setGuestModal(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    contentClassName="remove-white-bg"
                    backdrop="static"
                    centered
                >
                    <Modal.Body className="p-0">
                        <CloseIcon onClick={() => setGuestModal(false)} className='modal-close-icon' />
                        <Form {...guestSpeakerProps} />
                    </Modal.Body>
                </Modal>

                <Modal
                    show={writerModal}
                    onHide={() => setWriterModal(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    contentClassName="remove-white-bg"
                    backdrop="static"
                    centered
                >
                    <Modal.Body className="p-0">
                        <CloseIcon onClick={() => setWriterModal(false)} className='modal-close-icon' />
                        <Form {...guestWriterProps} />
                    </Modal.Body>
                </Modal>
        </>
    )
};

export default Contributor;