import React, { useState, useEffect } from 'react';

import '../../../assets/css/blogs.css';
import BlogCard from './BlogCard';
import { Link, useNavigate } from 'react-router-dom';
import BlueRectangleImage from '../../../assets/img/blue_rectangle.png';
import Book from '../../../assets/img/book.png';
import moment from 'moment';

const BlogsSection = ({ setTopBlogs, blogs, showReadMore }) => {

    const [otherBlogs, setOtherBlogs] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (blogs.length) {
            setTopBlogs([blogs[0], blogs[1], blogs[2]])
            let temp = []
            for (let i = 3; i < blogs.length; i += 1) {
                temp.push(blogs[i])
            }
            setOtherBlogs(temp)
        }
    }, [blogs])

    return (
        <>
       
        <section className="row g-3">
      {/* Main Featured Blog */}
    <div className="col-md-6">
  {blogs[0] && (
    <div
      className="card border-0 h-100"
      style={{
        minHeight: "300px", // Reduced from 400px to 300px
      }}
    >
      <div
        className="card-img h-100 position-relative rounded overflow-hidden"
        style={{
          backgroundImage: `url(${blogs[0].images["img-main-img"].url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="card-img-overlay d-flex flex-column justify-content-between h-100 p-3 main-blogsection-blog-card" 
        >
          {/* Date */}
          <span className="text-white small">{moment(blogs[0].publishedDate).format("Do MMMM, YYYY")}</span>

          {/* Content */}
          <div className="mt-auto">
            <Link to={`/research-articles/${blogs[0].url}`} className="text-decoration-none">
              <h3
                className="text-white fw-bold mb-2 main-blogsection-h3"
              >
                {blogs[0].title}
              </h3>
            </Link>

            {/* Meta Information */}
            <div className="d-flex flex-wrap align-items-center gap-2 text-white small">
              <span className="d-flex align-items-center">
                <i className="fa-solid fa-book me-2"></i>
                {blogs[0]?.blogReadTime || "10"} min read
              </span>
              <span className="mx-2">|</span>
              <div className="d-flex flex-wrap align-items-center">
                {blogs[0].categories.map((cat, index) => (
                  <React.Fragment key={index}>
                    <Link to={`/research-articles/category/${cat}`} className="text-white text-decoration-none">
                      {cat}
                    </Link>
                    {index !== blogs[0].categories.length - 1 && <span className="mx-1">,</span>}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
</div>

      {/* Secondary Blogs */}
      <div className="col-md-3">
        <BlogCard blog={blogs[1]} />
      </div>
      <div className="col-md-3">
        <BlogCard blog={blogs[2]} />
      </div>
    </section>
            <section className='w-100 row my-2'>
                {
                    showReadMore && otherBlogs.length > 7 ?
                        otherBlogs.slice(0, 7).map(blog => (
                            <div className='col-sm-12 col-md-3 p-1'>
                                <BlogCard blog={blog} />
                            </div>
                        ))
                        :
                        otherBlogs.map(blog => (
                            <div className='col-sm-12 col-md-3 p-1'>
                                <BlogCard blog={blog} />
                            </div>
                        ))
                }
                {
                    showReadMore && otherBlogs.length > 7 &&
                    <div className='col-sm-12 col-md-3 p-1'>
                        <div className='p-1'>
                            <Link to={`/research-articles/category/all`}>
                                <div className='corner read-more-card d-flex flex-column justify-content-center align-items-center gap-3'>
                                    <img className='icon-img' src={Book} alt="book" />
                                    <span className='text-white'>Read all blog articles</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

export default BlogsSection;