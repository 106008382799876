const news = [
    'www.hospitality.economictimes.indiatimes.com/news/hotels/navi-mumbai-hotel-prices-surge-ahead-of-coldplay-concert-2025-says-report/116991129',
    'www.businessworld.in/article/travel-2025-a-new-era-of-sustainability-technology-meaningful-journeys-543519',
    'www.cxotoday.com/press-release/sciative-solutions-unveils-zettaprice-a-groundbreaking-revenue-management-system-for-the-hospitality-industry/',
    'www.yourstory.com/smbstory/smb-wrap-archies-covid-19-sciative-pinklay',
    'www.travel.economictimes.indiatimes.com/speakeasy/dynamic-pricing/5047',
    'www.indianretailer.com/article/technology/digital-trends/tweet-buy-twitter-s-shop-module-pilot-launched-in-us-to-enable-shopping-on-platform.a7268',
    'www.newindianexpress.com/business/2021/Jul/16/dissecting-the-relevance-of-flash-sales-2330737.html',
    'www.dqindia.com/e-commerce-industry-necessitate-healthy-mix-predictive-prescriptive-analytics-models/',
    'www.travel.economictimes.indiatimes.com/news/technology/ai-based-pricing-decisions-can-plug-revenue-leakages-in-travel-industry-anshu-jalora/84404190',
]

export default news;